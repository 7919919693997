<template>
  <section class="section">
    <div class="section-header">
      <h1>Statistic</h1>
    </div>
    <div class="section-body">
      <div class="row">
        <div class="form-group">
          <h4 class="font-weight-bold">Income - Outcome</h4>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label for>Account Code</label>
                <select class="form-control" v-model="filter.account_code_id" id>
                  <option value>-- Select Account Code --</option>
                  <option :value="accountCode.id" :key="accountCode.id" v-for="accountCode in accountCodes">{{accountCode.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for>Venue</label>
                <select class="form-control" v-model="filter.venue_id" id>
                  <option value>-- Select Venue --</option>
                  <option :value="venue.value" :key="venue.value" v-for="venue in venues">{{venue.text}}</option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for>Department</label>
                <select class="form-control" v-model="filter.department_id" id>
                  <option value>-- Select Department --</option>
                  <option :value="department.id" :key="department.id" v-for="department in departments">{{department.name}}</option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for>Supplier</label>
                <select class="form-control" v-model="filter.supplier_id" id>
                  <option value>-- Select Supplier --</option>
                  <option :value="supplier.id" :key="supplier.id" v-for="supplier in suppliers">{{supplier.name}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <div class="form-group w-100">
                <button class="badge badge-primary w-100 shadow" @click="filter.time = timeFilters[0]">Daily</button>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <button class="badge badge-primary w-100 shadow" @click="filter.time = timeFilters[2]">Monthly</button>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <button class="badge badge-primary w-100 shadow" @click="filter.time = timeFilters[3]">Yearly</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <canvas ref="chart" style="max-height: 360px"></canvas>
      </div>
    </div>
  </section>
</template>

<script>
import { Chart, registerables } from "chart.js";
import { DepartmentRepository, VenueRepository, AccountCodeRepository, SupplierRepository } from '../../repositories/RepositoryFactory';
import { apiGet } from '../../services/api';

Chart.register(...registerables);

export default {
  data() {
    return {
      venues: [],
      departments: [],
      accountCodes: [],
      suppliers: [],

      chart: null,
      labels: [],
      data: {},

      timeFilters: ["M-d", "M-W", "Y-M", "Y"],
      filter: {
        account_code_id: "",
        venue_id: "",
        supplier_id: "",
        department_id: "",
        
        time: 'Y-M',
        year: null,
        month: null,
      }
    };
  },
  methods: {
    random(length) {
      return Math.floor(Math.random() * length);
    },
    setHeader(text, value) {
      return { text, value };
    },
    async getVenue() {
      VenueRepository.getVenueOnlyForSelect().then(({data}) => {
        this.venues = data.data
      })
    },
    async getDepartment() {
      DepartmentRepository.get().then(({data}) => {
        this.departments = data.data
      })
    },
    async getAccountCode() {
      AccountCodeRepository.get().then(({data}) => {
        this.accountCodes = data.data
      })
    },
    async getSuppliers() {
      SupplierRepository.get().then(({data}) => {
        this.suppliers = data.data
      })
    },
    async getData() {
      apiGet('dashboard/accounting/statistic', this.filter).then(({data}) => {
        this.data = data.data
      });
    }
  },
  mounted() {
    this.getData()
    this.getVenue()
    this.getDepartment()
    this.getAccountCode()
    this.getSuppliers()

    this.chart = new Chart(this.$refs.chart, {
      type: "line",
      data: {
        labels: this.data.params,
        datasets: [],
      },
    });
  },
  watch: {
    filter: {
      handler(val) {
        this.getData()
      },
      deep: true
    },
    data: {
      handler(val) {
        console.log(val)
        if(val == null || val == {}) {
          return;
        }
        this.chart.config.data.labels = val.params
        this.chart.config.data.datasets = [
          {
            label: "Outcome",
            data: (function() {
              let arr=[]
              for(let i=0;i<val.params.length;i++) {
                for(let j=0;j<Object.entries(val.outcome).length;j++) {
                  if(Object.keys(val.outcome)[j].includes(val.params[i])) {
                    arr.push(Object.values(val.outcome)[j])
                  } else {
                    arr.push(0)
                  }
                }
              }
              return arr;
            })(),
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ]
          },
          {
            label: "Income",
            data: (function() {
              let arr=[]
              for(let i=0;i<val.params.length;i++) {
                for(let j=0;j<Object.entries(val.income).length;j++) {
                  if(Object.keys(val.income)[j].includes(val.params[i])) {
                    arr.push(Object.values(val.income)[j])
                  } else {
                    arr.push(0)
                  }
                }
              }
              return arr;
            })(),
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ]
          },
        ]
        this.chart.update()
        this.chart.render()

        console.log(this.chart)
      },
      deep: true
    }
  }
};
</script>